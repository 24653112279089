body {
  background-color: #F0F0F2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
.css-yqd1uu > .MuiGrid-item{
  padding: 0 !important;
}
.App {
  text-align: center;
}

.BodyMain {
  background-color: #F0F0F2;
  display: flex;
  flex-direction: row;
}
@media (min-width: 900px) {
  .BodyMain {
    height: calc(100vh - 123px);
  }
}
@media (max-width: 900px) and (max-width: 1200px) {
  .BodyMain {
    flex-direction: column;
    align-items: center;
  }
}

.sectionCardDragDrop {
  width: 100%;
}

.pDragDrop {
  color: #737373;
}

/* Estilos para Card */
.card-container {
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  position: relative;
}

.content-container {
  flex: 1;
  margin-right: 15px;
}

.button-link {
  text-decoration: none;
}

.details-button {
  border-radius: 20px;
  color: white;
  background-color: #316094;
}

.point {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.textSectionCardDragDrop {
  text-align: left;
  padding: 16px;
}

.itemsPdfViewer {
  padding: 0px 2px;
}


.errorEmail {
  background-color: #cb3234;
  color: white;
  margin: 20px;
  border-radius: 23px;
  padding: 10px;
}

.containerEmail {
  display: flex;
  flex-direction: column;
}

.error-container {
  margin-bottom: 10px;
}

.error-message {
  color: #cb3234;
  font-size: 16px;
}

.checkbox {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonShow {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.currentPageInput {
  padding: 0px 2px;
  width: 2rem;
}

.goToPreviousPage {
  padding: 0px 2px;
  margin-left: auto;
}

.containerTool {
  align-items: center;
  display: flex;

  margin: auto;
}


.popupStatusEndpoint {
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popupStatusEndpoint-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 20px;
}

.buttonpopupStatusEndpoint {
  border-color: transparent;
  background: transparent;
  color: white;
}

/* Estilos para InfoSection */
#info-section {
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}







#info-section h2 {
  color: #333;
}

#info-section p {
  margin: 10px 0;
}

#info-section .table-container {
  overflow-x: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

#info-section .custom-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 10px;
}

#info-section .custom-table th,
#info-section .custom-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

#info-section .custom-table th {
  background-color: #f2f2f2;
}

#info-section .custom-table tbody tr:last-child td {
  border-bottom: none;
}

#info-section .custom-table td:last-child {
  border-right: none;
}

#info-section .custom-table th,
#info-section .custom-table td {
  border-bottom: 2px solid #ddd;
}

#info-section .custom-table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.confirmation-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
}

.confirmation-modal p {
  margin-bottom: 20px;
}

.confirmation-modal button {
  margin: 0 10px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
}

.confirmation-modal .close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #000;
  z-index: 1;
}

/* Añadir transiciones suaves para una apariencia más agradable */
.confirmation-modal {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

/* Añadir opacidad y fondo oscuro cuando se muestra el modal */
.show-modal {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

#firmar-button {
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 5px;
  cursor: pointer;
  border-radius: 4px;
}

#negar-firma-link {
  color: gray;
  text-decoration: none;
  margin-right: 27px;
}

#firmar-button {
  background-color: #4caf50;
}

/* Agrega estilos para hacer que los botones de zoom sean flotantes */
.responsive-buttons {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 999;
  top: 230px;
  right: 0;
  margin-right: 10px;
}

.qr-code {
  margin-right: 15px;
}

.responsive-buttons button {
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .content-container {
    margin-right: 0;
    margin-bottom: 15px;
    /* Espacio entre el contenido y el botón en pantallas pequeñas */
  }

  .point {
    top: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .card-container {
    flex-direction: column;
    align-items: flex-start;
  }



  .content-container {
    margin-right: 0;
  }

  .containerTool {
    align-items: center;
    max-width: 230px;
  }

  .containerTool .itemsPdfViewer {
    display: block;
  }

  .buttonLeftRight {
    display: none;
  }

  #info-section {
    max-width: 100%;
  }
}

/* Media query for medium-sized screens */
@media only screen and (min-width: 601px) and (max-width: 900px) {



  .containerTool {
    max-width: 400px;
  }

  .containerTool .itemsPdfViewer {
    display: flex;
    margin-bottom: 0;
  }

  .buttonLeftRight,
  .itemsPdfViewer:last-child {
    display: flex;
  }
}

.containerTool {
  max-width: 400px;
}

/* Componentes de react-pdf-viewer */
.rpv-default-layout__sidebar-tabs {
  display: none !important;
}

.rpv-default-layout__toolbar {
  background-color: #316094 !important;
  justify-content: center;
}

.floating-message {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
}

.rpv-default-layout__container {
  border: 0;
}

.successMessage {
  color: #4caf50;
  border: 1px solid #4caf50;
}

@font-face {
  font-family: robotoBlack;
  src: url(../public/fonts/Roboto-Black.ttf);
}

@font-face {
  font-family: robotoBlackItalic;
  src: url(../public/fonts/Roboto-BlackItalic.ttf);
}

@font-face {
  font-family: robotoBold;
  src: url(../public/fonts/Roboto-Bold.ttf);
}

@font-face {
  font-family: robotoBoldItalic;
  src: url(../public/fonts/Roboto-BoldItalic.ttf);
}

@font-face {
  font-family: robotoItalic;
  src: url(../public/fonts/Roboto-Italic.ttf);
}

@font-face {
  font-family: robotoLight;
  src: url(../public/fonts/Roboto-Light.ttf);
}

@font-face {
  font-family: robotoLightItalic;
  src: url(../public/fonts/Roboto-LightItalic.ttf);
}

@font-face {
  font-family: robotoMediumItalic;
  src: url(../public/fonts/Roboto-MediumItalic.ttf);
}

@font-face {
  font-family: robotoMedium;
  src: url(../public/fonts/Roboto-Medium.ttf);
}

@font-face {
  font-family: robotoThinItalic;
  src: url(../public/fonts/Roboto-ThinItalic.ttf);
}

@font-face {
  font-family: robotoThin;
  src: url(../public/fonts/Roboto-Thin.ttf);
}

@font-face {
  font-family: robotoRegular;
  src: url(../public/fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: robotoCondensedItalic;
  src: url(../public/fonts/RobotoCondensed-Italic-VariableFont_wght.ttf);
}

@font-face {
  font-family: robotoCondensedVariableFont;
  src: url(../public/fonts/RobotoCondensed-VariableFont_wght.ttf);
}