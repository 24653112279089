.DivCardSignedDocuments {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px;
}

.blue-backgroundSignedDocuments {
    max-width: 309px;
    background-color: #316094;
    display: flex;
    justify-content: center;
    align-items: center;
}
.color-bandSigner {
    background-color: #316094;

}
.css-ne6dyd-MuiFormControl-root-MuiTextField-root {
    margin-top: 0 !important;
}
.iconButtonSigner {
    border-radius: 0px 30px 30px 0px !important;
    background-color: rgb(49, 96, 148) !important;
}

.iconButtonContainerSigner {
    border-radius: 0;
    background-color: #E2E2E5 !important;
}

@media (max-width: 900px) {
    .rectangleSignedDocuments {
        padding: 10px 20px;
    }
    .blue-backgroundSignedDocuments {
        width: 100%;
        max-width: 100%;
    }
    .iconButtonSigner {
        transform: rotate(90deg) !important;
        margin-bottom: 10px !important;
    }
    .color-bandSigner {
        width: 100%;
        height: 20px;
    }
    .iconButtonContainerSigner {
        width: 100%;
    }
    .scroll-containerSignedDocuments {
        height: 100%;
        flex-direction: column;
        overflow-y: unset !important;
        align-items: center !important;
    }
}

.rectangleSignedDocuments {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.scroll-containerSignedDocuments {
    width: 100%;
    background-color: #E2E2E5;
    display: flex;
    align-items: flex-start;
    overflow-y: auto;
    justify-content: center;
}

@media (min-width: 900px) {
    .scroll-containercontainerSignedDocuments{
        justify-content: center;
    }
    .rectangleSignedDocuments {
        height: 85%;
        
    padding: 20px 20px;
    }

    .iconButtonSigner {
        top: 50px;
        height: 40px;
        position: relative;
    }

    .color-bandSigner {
        width: 20px;
        height: 100%;
    }

    .iconButtonContainerSigner {
        height: 100%;
        display: flex;
        flex-direction: row;
    }
}

.scroll-containercontainerSignedDocuments .MuiGrid-container {
    background-color: #F0F0F2;
    width: 90%;
    padding: 16px;
    margin-top: 40px;
    box-sizing: border-box;
}