.DivCardDocumentsInProcess {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px;
}

.iconButtonProcess {
    border-radius: 0px 30px 30px 0px !important;
    background-color: rgb(49, 96, 148) !important;
}

.color-bandProcess {
    background-color: #316094;

}

.iconButtonContainerProcess {
    border-radius: 0;
    background-color: #E2E2E5 !important;
}

.blue-backgroundDocumentsInProcess {
    max-width: 309px;
    background-color: #316094;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 900px) {
    .rectangleDocumentsInProcess {
        padding: 10px 20px;
    }

    .blue-backgroundDocumentsInProcess {
        width: 100%;
        max-width: 100%;
    }

    .iconButtonProcess {
        transform: rotate(90deg) !important;
        margin-bottom: 10px !important;
    }

    .iconButtonContainerProcess {
        width: 100%;
    }

    .color-bandProcess {
        width: 100%;
        height: 20px;
    }

    .scroll-containerDocumentsInProcess {
        height: 100%;
        flex-direction: column;
        overflow-y: unset !important;
        align-items: center !important;
    }
}

@media (min-width: 900px) {
    .rectangleDocumentsInProcess {
        height: 85%;
        padding: 20px 20px;
    }

    .iconButtonProcess {
        top: 50px;
        height: 40px;
        position: relative;
    }

    .color-bandProcess {
        width: 20px;
        height: 100%;
    }

    .iconButtonContainerProcess {
        height: 100%;
        display: flex;
        flex-direction: row;
    }
}


.css-ne6dyd-MuiFormControl-root-MuiTextField-root {
    margin-top: 0 !important;
}

.rectangleDocumentsInProcess {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.scroll-containerDocumentsInProcess {
    width: 100%;
    background-color: #E2E2E5;
    display: flex;
    align-items: flex-start;
    overflow-y: auto;
}

@media (min-width: 900px) {

    .scroll-containerDocumentsInProcess {
        justify-content: center;
    }
}

.scroll-containerDocumentsInProcess .MuiGrid-container {
    background-color: #F0F0F2;
    width: 90%;
    padding: 16px;
    margin-top: 40px;
    box-sizing: border-box;
}