.containerLogoPoderJudicial {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.css-azmdh8-MuiToolbar-root {
    min-height: 0 !important;
}

.boxTabs {
    height: 100%;
    display: flex;
    overflow-x: auto;
    /* justify-content: center; */
}

@media (min-width: 900px) and (max-width: 1200px) {
    .boxTabs {
        width: 95%;
    }
    .containerLogoPoderJudicial{
        width: 95%;
    }
}

@media (min-width: 1200px) {
    .boxTabs {
        width: 100%;
    }

    .logoNavbar {
        margin-right: 35px;
    }
    .IconButtonNavbar{
        margin-left: 15px;
    }
    .containerLogoPoderJudicial{
        width: 95%;
    }
}

.css-kr3iqj-MuiToolbar-root {
    min-height: 0 !important;
}

.logoNavbarPoderJudicial {
    max-width: 180px;
    margin-right: 30px;
}

.css-k008qs {
    height: 100% !important;
}

.logoNavbar {
    max-width: 140px;
    padding-left: 20px;
}

/*  */
@media (max-width: 900px) {

    .LogoContainer {
        display: none !important;
    }

    .logoNavbar {
        display: none !important;
    }

    .LogoContainerFirmaMobile {
        display: flex !important;
        justify-content: center;
    }

    .LogoNavbarFirmaCiudadanaMobile {
        max-width: 150px;
    }

    .ToolbarNavbar {
        justify-content: space-between !important;
    }
    .containerLogoPoderJudicial{
        width: 85%;
    }
}

.LogoContainerFirmaMobile {
    display: none;
}

.menu-panel {
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    height: 100%;
    background-color: #fff;
    z-index: 1000;
    transition: left 0.3s ease;
}

.menu-panel.open {
    left: 0;

}

.menu-panel ul {
    list-style-type: none;
    padding: 0;
}

.menu-panel li {
    padding: 10px 20px;
    cursor: pointer;
}

.menu-panel li:hover {
    background-color: #f0f0f0;
}

/* .css-1k33q06 {
    color: white;
} */

.UserBoxButtonNavbar {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
}

.buttonOutNavbar {
    background-color: #316094 !important;
    font-size: 12px !important;
    border-radius: 4px;
}

.salirSesion {
    font-family: robotoRegular;
    max-height: 17px;
}

.UserBoxBienvenidoTextNavbar {
    font-weight: bold !important;
    font-size: 14px !important;
}

.UserBoxNormalTextNavbar {
    font-family: robotoRegular !important;
    font-size: 12px !important;
}

.IconButtonNavbar {
    color: white;
}

.TypographyMenuItemNavbar {
    opacity: 1;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    border-radius: 0 !important;
    box-shadow: none !important;
}