.DivCardExpiredAndRejectedDocuments {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px;
}

.blue-backgroundExpiredAndRejectedDocuments {
    max-width: 309px;
    background-color: #316094;
    display: flex;
    justify-content: center;
    align-items: center;
}

.css-ne6dyd-MuiFormControl-root-MuiTextField-root {
    margin-top: 0 !important;
}

.rectangleExpiredAndRejectedDocuments {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}
.iconButtonExpired {
    border-radius: 0px 30px 30px 0px !important;
    background-color: rgb(49, 96, 148) !important;
}
.color-bandExpired {
    background-color: #316094;

}

.iconButtonContainerExpired {
    border-radius: 0;
    background-color: #E2E2E5 !important;
}
@media (max-width: 900px) {
    .rectangleExpiredAndRejectedDocuments {
        padding: 10px 20px;
    }
    .blue-backgroundExpiredAndRejectedDocuments {
        width: 100%;
        max-width: 100%;
    }

    .scroll-containerExpiredAndRejectedDocuments {
        height: 100%;
        flex-direction: column;
        overflow-y: unset !important;
        align-items: center !important;
    }
    .iconButtonExpired {
        transform: rotate(90deg) !important;
        margin-bottom: 10px !important;
    }
    .iconButtonContainerExpired {
        width: 100%;
    }
    .color-bandExpired {
        width: 100%;
        height: 20px;
    }
}

.scroll-containerExpiredAndRejectedDocuments {
    width: 100%;
    background-color: #E2E2E5;
    display: flex;
    
    align-items: flex-start;
    overflow-y: auto;
}

@media (min-width: 900px) {
    .scroll-containerExpiredAndRejectedDocuments{
        justify-content: center;
    }
    .rectangleExpiredAndRejectedDocuments {
        height: 85%;
    padding: 20px 20px;
    }
    .iconButtonExpired {
        top: 50px;
        height: 40px;
        position: relative;
    }

    .color-bandExpired {
        width: 20px;
        height: 100%;
    }

    .iconButtonContainerExpired {
        height: 100%;
        display: flex;
        flex-direction: row;
    }
}

.scroll-containerExpiredAndRejectedDocuments .MuiGrid-container {
    background-color: #F0F0F2;
    width: 90%;
    padding: 16px;
    margin-top: 40px;
    box-sizing: border-box;
}