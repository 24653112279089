.drag-drop-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #316094;
  min-height: 86vh;
}

@media(max-width: 900px) {
  .drag-drop-container {
    min-height: 86vh;
  }
}

@media(min-width: 900px) {
  .drag-drop-container {
    width: 375px;
  }
}


.upload-button {
  background-color: #316094;
  color: white;
  font-family: robotoMedium;
  font-size: 12px;
  border: none;
  padding: 10px 60px;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #2a4d75;
}

.container {
  border-radius: 0;
  padding: 25px;
  text-align: center;
  box-shadow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container>div {
  margin: 0 auto;
  padding: 10px 40px;
  background-color: white;
  overflow: hidden;
  min-height: 86vh;
}

.dropzone {
  width: 80%;
  align-items: center;
  margin: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.upload-image {
  height: auto;
  margin-bottom: 10px;
  display: block;
  margin: auto;
}
.pDragDrop{
  font-family: robotoMedium;
  font-size: 12px;
}
.error-message {
  color: red;
  font-family: robotoMedium;
  font-size: 12px;
  list-style: none;
  margin: 0;
  padding: 0;
}