.pdfContainer {
    width: 100%;
    position: relative;
}

@media (min-width: 900px) {
    .pdfContainer {
        min-height: 86vh;
    }

    .rpv-core__textbox {
        margin-bottom: 10px !important;
    }
}

.viewerAndToolbarContainer {
    display: flex;
    height: 100%;
}

.viewerContainer {
    flex: 1;
    height: auto;
    overflow-y: auto;
}

.viewerContainer::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.textPdfViewerMobile {
    background: white;
    width: 100%;
    text-align: center;
    color: #316094;
    padding-bottom: 5px;
    padding-top: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: "robotoMedium";
    font-size: 14px;
}

.toolbarContainerMobile {
    display: flex;
    background: #316094;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.toolbarContainer {
    align-items: center;
    background-color: #316094;
    border: 1px solid #316094(0, 0, 0, 0.2);
    border-radius: 2px;
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    justify-content: flex-start;
    color: white;
    height: auto;
}

.rpv-page-navigation__current-page-input {
    width: 100%;
    display: flex;
    margin: 0;
    justify-content: center;
}

.currentPageInput {
    padding: 0px 2px;
    color: white;
}
.currentPageInputMobile{
    background: white;
    color: #316094;
    width: 90%;
    padding: 5px;
    margin-right: 10px;
}
.itemsToolbar {
    padding: 0px 2px;
    color: white;
}

.rpv-core__icon {
    color: white !important;
    stroke-width: 2 !important;
}

.rpv-default-layout__toolbar {
    position: relative;
}

.rpv-core__textbox {
    width: 70% !important;
    color: #316094 !important;
    border: none !important;
    border-radius: 0 !important;
}

.rpv-default-layout__toolbar {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    height: auto !important;
    position: absolute !important;
    width: 15% !important;
}

.rpv-core__inner-page {
    background-color: #F0F0F2 !important;
}

/* .rpv-core__canvas-layer{
    margin-top: 30px !important;
} */
.textPdfViewer {
    background-color: white;
    font-size: 15px;
    color: #316094;
    text-align: center;
    font-size: 16px;
    font-family: robotoCondensedItalic;
    padding: 20px;
}


@media only screen and (min-width: 601px) and (max-width: 900px) {
    .pdfContainer {
        height: 80vh;
    }
}

/* Media query for large screens */
@media only screen and (min-width: 901px) {
    .pdfContainer {
        height: 80vh;
    }
}