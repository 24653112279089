.css-e4w4as-MuiFormLabel-root-MuiInputLabel-root {
    color: black !important;
}

input,
button {
    box-sizing: border-box;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    text-align: left !important;
}


.css-qc420c-MuiTypography-root {
    font-size: 16px !important;
}

.css-1mog9k0-MuiFormLabel-root-MuiInputLabel-root {
    color: black !important;
}

.css-1wc848c-MuiFormHelperText-root.Mui-error {
    color: white !important;
}

.css-1vbc0rj-MuiInputBase-input-MuiFilledInput-input {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
}

.css-ofrcno-MuiGrid-root>.MuiGrid-item {
    padding-top: 0 !important;
    padding-bottom: 10px !important;
}

.css-tbd9d2-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    border-radius: 0 !important;
}

.css-5w4a1d-MuiButtonBase-root-MuiButton-root:hover {
    background-color: transparent;
    box-shadow: none;
}

.css-13l6k1w-MuiButtonBase-root-MuiButton-root:hover {
    background-color: white !important;
}

.css-1njz7x1 {
    color: black !important;
}


/* Botón de enviar */
.sendButton {
    margin-top: 20px;
    background-color: #316094;
    color: white;
    border-radius: 0;
    box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.1);
    padding: 12px 24px;
    transition: background-color 0.3s ease;
}

.sendButton:hover {
    background-color: #25456b;
}

/* Mensajes de Snackbar */
.snackbar {
    bottom: 20px;
}

/* Formulario para agregar firmantes */

.firmantesText {
    text-align: initial;
    color: white;
}

/* Botón para agregar firmante */
.addButton {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
}

.rectangle {
    height: 85%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

@media(max-width: 1100px) {
    .rectangle {
        padding-top: 50px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: white transparent;
    }
}

@media(min-width: 1100px) {
    .rectangle {
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 50px;
    }
}

@media(max-width: 900px) {
    .rectangle {
        background-color: #316094;
        margin: auto;
    }
}

@media(min-width: 900px) {
    .css-1d6wzja-MuiButton-startIcon {
        margin-left: 24px !important;
    }
}

/* Icono de agregar firmante */
.agregarFirmante {
    width: 28px;
    transition: opacity 0.3s ease;
}

/* Mensajes de error */
.errorMessage {
    color: #ff1744;
    margin-top: 8px;
}

/* Botón de eliminar firmante */
.deleteButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

/* Icono de eliminar firmante */
.deleteIcon {
    color: #ff1744;
    transition: color 0.3s ease;
}

.deleteIcon:hover {
    color: #d50000;
}