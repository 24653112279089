
.DivCardDocumentsToSign {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px;
}
.css-lttavi-MuiGrid-root>.MuiGrid-item{
    padding: 0 !important;
}
.blue-backgroundDocumentsToSign {
    max-width: 309px;
    background-color: #316094;
    display: flex;
    justify-content: center;
    align-items: center;
}
.css-ne6dyd-MuiFormControl-root-MuiTextField-root{
    margin-top:0 !important;
}
.rectangleDocumentToSign {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}
.scroll-containerDocumentsToSign {
    width: 100%;
    background-color: #E2E2E5;
    display: flex;
    align-items: flex-start;
    overflow-y: auto; 
}
.iconButtonToSign {
    border-radius: 0px 30px 30px 0px !important;
    background-color: rgb(49, 96, 148) !important;
}
.color-bandToSign {
    background-color: #316094;
    
}
.iconButtonContainerToSign {
    border-radius: 0;
    background-color: #E2E2E5 !important;
}
@media (max-width: 900px) {
    .rectangleDocumentToSign {
        padding: 10px 20px;
    }
    .blue-backgroundDocumentsToSign {
        width: 100%;
        max-width: 100%;
    }
    .scroll-containerDocumentsToSign { 
        height: 100%;
        flex-direction: column;
        overflow-y: unset !important;
        align-items: center !important;
    }
    .iconButtonToSign {
        transform: rotate(90deg) !important;
        margin-bottom: 10px !important;
    }
    .iconButtonContainerToSign {
        width: 100%;
    }
    .color-bandToSign {
        width: 100%;
        height: 20px;
    }
}

@media (min-width: 900px) {
    .rectangleDocumentToSign {
        height: 85%;
        
    padding: 20px 20px;
    }
    .scroll-containerDocumentsToSign {
        justify-content: center;
        max-height: 100%;
    }
    .iconButtonToSign {
        top: 50px;
        height: 40px;
        position: relative;
    }

    .color-bandToSign {
        width: 20px;
        height: 100%;
    }

    .iconButtonContainerToSign {
        height: 100%;
        display: flex;
        flex-direction: row;
    }
}
.scroll-containerDocumentsToSign .MuiGrid-container {
    background-color: #F0F0F2;
    width: 90%;
    padding: 16px;
    margin-top: 40px;
    box-sizing: border-box;
}