.blue-backgroundPublish {
    min-width: 353px;
    background-color: #316094;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: #316094;
}

.css-ne6dyd-MuiFormControl-root-MuiTextField-root {
    margin-top: 0 !important;
}
.color-band {
    background-color: #316094;

}
.white-rectangle {
    background-color: white;
    width: 70%;
    justify-content: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.rectangle {
    width: 340px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.iconButtonPublish{
    border-radius: 0px 30px 30px 0px !important;
    background-color: rgb(49, 96, 148) !important;
}
@media (max-width: 900px) {
    .rectangle {
        padding: 10px 20px;
    }
    .blue-backgroundPublish {
        width: 100%;
        max-width: 100%;
        height: calc(100vh - 123px);
    }

    .white-rectangle {
        height: 85%;
    }
    .iconButtonPublish{
        transform: rotate(90deg) !important;
        margin-bottom: 10px !important;
    }
    .scroll-containerPublishedContainer {
        flex-direction: column;
        overflow-y: unset !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .color-band {
        width: 100%;
        height: 20px;
    }
}

@media (min-width: 900px) {
    .rectangle {
        height: 85%;
        padding: 20px 20px;
    }

    .white-rectangle {
        height: 50%;
    }
    .iconButtonPublish {
        top: 50px;
        height: 40px;
        position: relative;
    }

    .color-band {
        width: 20px;
        height: 100%;
    }

    
}

.scroll-containerPublishedContainer {
    width: 100%;
    background-color: #E2E2E5;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* height: 85vh; */
    overflow-y: auto;
}

.scroll-containerPublishedContainer .MuiGrid-container {
    background-color: #F0F0F2;
    width: 90%;
    padding: 16px;
    margin-top: 40px;
    box-sizing: border-box;
}