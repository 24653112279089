.signature-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .signature-canvas {
    border: 1px solid #316094;
    width: 100%;
  }
  
  .button-container {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .action-button {
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
  }